export enum PreCachedImages {
  WINDOW_SILL = "/preCached/house/elements/window.sill.png",
  NEW_MARKER = "/preCached/bridge/crosshair_pink.svg",
  EXISTING_MARKER = "/preCached/bridge/crosshair_red.svg",
  BOX_CULVERT = "/preCached/bridge/box_culvert.png",
  INTEGRAL_SUPPORT = "/preCached/bridge/integral_support.png",
  SPAN_SUPPORT_CONTINUOUS = "/preCached/bridge/span_support_continuous.png",
  SPAN_SUPPORT_SIMPLY_SUPPORTED = "/preCached/bridge/span_support_simply_supported.png",
  CANONICAL_MODEL = "/preCached/bridge/box-culvert.glb",
}

export enum PreCached3dModels {
  BOX_CULVERT = "/preCached/bridge/canonical/box-culvert.glb",
  DOUBLE_SPAN_REINFORCED_CONCRETE = "/preCached/bridge/canonical/double-span-reinforced-concrete.glb",
  SINGLE_SPAN_SIMPLY_SUPPORTED = "/preCached/bridge/canonical/single-span-simply-supported.glb",
  SINGLE_SPAN_INTEGRAL_ABUTMENT = "/preCached/bridge/canonical/single-span-integral-abutment.glb",
}

export enum PreCachedPdf {
  TERMS_AND_CONDITIONS = "/preCached/houseMobile/terms-and-conditions.pdf",
}

export enum PreCachedFonts {
  // a base64 encoded version of the Noto Sans font using jsPDF to convert
  NOTO_SANS = "/preCached/house/fonts/notoSansFont.txt",
}
