const uuidRegex = "[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}";
const regex = new RegExp(`/report/${uuidRegex}`);

export const isAuthlessUrl = () => {
  // We are temporarily allowing authless access to the mobile app for report viewing
  // TODO: this is temporary as the mobile app will soon be fully authless
  // see: https://gitlab.com/mind-foundry/product/windward/peregrine/-/issues/445

  return regex.test(document.URL);
};
