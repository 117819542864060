import { throwError } from "@common/doNothing";
import { useGetCurrentUser } from "@common/requests/user";
import { AvailableRoutes } from "@common/routes";
import { updateApp } from "@common/updateApp";
import { useConfig } from "@config";
import { Spinner } from "@houseComponents/Spinner";
import { useAuth, useTranslation } from "@mind-foundry/mf-ui-core";
import { isBridgeRole, Role } from "@models/assets";
import { APIProvider } from "@vis.gl/react-google-maps";
import { AxiosError } from "axios";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { isAuthlessUrl } from "./common/auth";

export function App(): React.ReactElement {
  const config = useConfig();

  return (
    <APIProvider apiKey={config.gmaps.key}>
      <AppSelector />
    </APIProvider>
  );
}

function AppSelector(): React.ReactElement {
  const { data: user, error } = useGetCurrentUser();

  const [ChosenApp, setChosenApp] = useState<
    React.ComponentType<{ readonly routes?: ReadonlyArray<AvailableRoutes> }> | null
  >(null);

  const { t } = useTranslation();

  const availableRoutes = useMemo(() => {
    if (user?.role === Role.BRIDGE_INSPECTOR) {
      return [
        "home",
        "inspections",
        "inspectionPage",
        "annotationPage",
        "boundingBox",
        "newPhoto",
        "more",
        "language",
      ] as ReadonlyArray<AvailableRoutes>;
    }

    if (user?.role === Role.BRIDGE_ENGINEER) {
      return [
        "home",
        "inspectionPage",
        "annotationPage",
        "refineOverlay",
        "manualOverlay",
        "newAsset",
        "editAsset",
        "newPhoto",
        "more",
        "language",
      ] as ReadonlyArray<AvailableRoutes>;
    }

    return undefined;
  }, [user]);

  // Used to dynamically load either the house or bridge app based on the user's role
  // Prevents css clashes, improves performance and reduces bundle size
  useEffect(() => {
    if (isAuthlessUrl()) {
      import("./modules/houseMobile/HouseMobileApp")
        .then((module: { readonly HouseMobileApp: React.ComponentType }) => setChosenApp(() => module.HouseMobileApp))
        .catch(throwError);
    } else if (isBridgeRole(user?.role)) {
      type BridgeAppProps = React.ComponentType<{ readonly routes?: ReadonlyArray<AvailableRoutes> }>;

      import("./modules/bridge/BridgeApp")
        .then((module) => setChosenApp(() => module.BridgeApp as BridgeAppProps))
        .catch(throwError);
    } else if (user?.role && [Role.HOUSE_POLICYHOLDER, Role.HOUSE_INSPECTOR].includes(user?.role)) {
      import("./modules/houseMobile/HouseMobileApp")
        .then((module: { readonly HouseMobileApp: React.ComponentType }) => setChosenApp(() => module.HouseMobileApp))
        .catch(throwError);
    } else if (user?.role && [Role.HOUSE_SUPERVISOR, Role.HOUSE_REVIEWER, Role.HOUSE_OVERSEER].includes(user?.role)) {
      import("./modules/house/HouseApp")
        .then((module: { readonly HouseApp: React.ComponentType }) => setChosenApp(() => module.HouseApp))
        .catch(throwError);
    } else if (user?.role === Role.ADMIN) {
      import("./modules/admin/AdminApp")
        .then((module) => setChosenApp(() => module.AdminApp))
        .catch(throwError);
    } else if (user?.role) {
      // If the user has a role that is not supported, show an error page
      setChosenApp(() => AuthError);
    }
  }, [user]);

  if (error) {
    if ((error as AxiosError).response?.status === 403) {
      return <AuthError />;
    }

    return <ErrorPage errorMessage={t("app:UNKNOWN_ERROR")} />;
  }

  const waitingForUser = !user && !isAuthlessUrl();
  if (waitingForUser || !ChosenApp) {
    return <Spinner spinnerSize="large" />;
  }

  return <ChosenApp routes={availableRoutes} />;
}

// Error page that doesn't require the ionic framework and is independant of modules
function ErrorPage({
  errorMessage,
}: {
  readonly errorMessage: string;
}): React.ReactElement {
  const { t } = useTranslation();
  const { logout } = useAuth();

  return (
    <ErrorContent>
      <div className="error-container">
        <div className="header">
          <h1>{t("app:ERROR_PAGE_HEADER")}</h1>
        </div>
        <div className="content">
          <span className="error-icon">ⓘ</span>
          <div className="text">
            <h2>{errorMessage}</h2>
          </div>
          <div className="text">
            <h1>{t("app:AUTH_FAILED")}</h1>
          </div>
          <div className="button-container">
            <button
              type="button"
              className="action-button"
              onClick={logout}
            >
              {t("app:LOGOUT")}
            </button>
            <button
              type="button"
              className="action-button"
              onClick={updateApp}
            >
              {t("app:UPDATE_APP")}
            </button>
          </div>
        </div>
      </div>
    </ErrorContent>
  );
}

function AuthError(): React.ReactElement {
  const { t } = useTranslation();

  return <ErrorPage errorMessage={t("app:ERROR_PAGE_GUIDANCE")} />;
}

const ErrorContent = styled.div`
  font-family: Roboto, sans-serif;
  padding: 16px;

  .error-container {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 0 auto;
    padding-top: 15vh;
    gap: 24px;
    align-items: center;
  }

  .header {
    text-align: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }

  .text {
    text-align: center;
  }

  .error-icon {
    font-size: 8em;
  }

  .button-container {
    display: flex;
    gap: 20px;
  }

  .action-button {
    padding: 12px 24px;
    font-size: 1em;
    font-weight: bold;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }

  .action-button:hover {
    background-color: #0056b3;
  }
`;
