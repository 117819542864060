import { isAuthlessUrl } from "@common/auth";
import { useHttp } from "@common/requests/http";
import { AppUser } from "@models/assets";
import { Role } from "@models/assets";
import { AxiosError } from "axios";
import { useQuery, UseQueryResult } from "react-query";

interface GetUserOptions {
  readonly roles?: Role;
  readonly email?: string;
}

export const useGetCurrentUser = (): UseQueryResult<AppUser> => {
  const { http, errorHandler } = useHttp();

  return useQuery(["useGetCurrentUser"], async () => {
    if (isAuthlessUrl()) {
      return undefined;
    }

    const response = await http.get<AppUser>("/user/current").catch((error: AxiosError) => {
      errorHandler({
        error,
        errorMessage: "app:API_ERROR_GET_CURRENT_USER",
      });

      throw error;
    });

    return response.data;
  });
};

export const useGetUsers = (
  params: GetUserOptions = {},
): UseQueryResult<AppUser[]> => {
  const { http, errorHandler } = useHttp();
  const paramString = JSON.stringify(params);

  return useQuery(
    ["useGetUsers", paramString],
    (): Promise<ReadonlyArray<AppUser>> =>
      http
        .get<ReadonlyArray<AppUser>>("/user/search", { params })
        .then((res) => (res.data ?? [])),
    {
      staleTime: Infinity,
      onError: (error: AxiosError) =>
        errorHandler({
          error,
          errorMessage: "app:API_ERROR_GET_USERS",
        }),
    },
  );
};
