export const doNothing = (): void => {};

export const doNothingWithEvent = (e: Event | React.SyntheticEvent): void => {
  e.preventDefault();
};

// A do nothing for use in catch blocks to handle @typescript-eslint/no-floating-promises
export const throwError = (e: Error): void => {
  throw e;
};
