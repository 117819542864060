/* eslint-disable camelcase */
import { PreCachedImages } from "@common/images";
import { Id } from "@common/localStorage/common";
import { homeOutline } from "ionicons/icons";

import { Annotation } from "./annotations";
import { ItemWithId } from "./base";

export enum Role { // same as backend/src/models.py
  NONE = "none",
  ADMIN = "admin",
  BRIDGE_INSPECTOR = "bridge_inspector",
  BRIDGE_ENGINEER = "bridge_engineer",
  HOUSE_POLICYHOLDER = "house_policyholder",
  HOUSE_INSPECTOR = "house_inspector",
  HOUSE_SUPERVISOR = "house_supervisor",
  HOUSE_OVERSEER = "house_overseer",
  HOUSE_REVIEWER = "house_reviewer",
}

export type RoleOrNull = Role | null;

const bridgeRoles = [Role.BRIDGE_INSPECTOR, Role.BRIDGE_ENGINEER];

export const isBridgeRole = (role?: RoleOrNull): boolean => !!role && bridgeRoles.includes(role);

export interface AppUser {
  readonly id: string;
  readonly email: string;
  readonly blocked: boolean;
  readonly language?: string;
  readonly last_login?: Date;
  readonly last_update?: Date;
  // An authenticated user will always have a role, but our AppUser db table must allow nulls because (annoyingly)
  // Auth0 doesn't include Roles in its Users API response, which we use to refresh our table - see user_admin.py
  readonly role: RoleOrNull;
}

// Values correspond to translation message keys in app.json
export enum AssetType { // same as backend/src/models.py
  BOX_CULVERT = "BOX_CULVERT",
  INTEGRAL_ABUTMENT_BRIDGE = "INTEGRAL_ABUTMENT_BRIDGE",
  SIMPLY_SUPPORTED_SINGLE_SPAN_BRIDGE = "SIMPLY_SUPPORTED_SINGLE_SPAN_BRIDGE",
  DOUBLE_SPAN_CONTINUOUS_BRIDGE = "DOUBLE_SPAN_CONTINUOUS_BRIDGE",
  HOUSE = "HOUSE",
}

interface DraftAsset extends ItemWithId {
  readonly thumbnail: string;
  readonly address: string;
  readonly type: AssetType;
  readonly orientation: number;
  readonly latitude: number;
  readonly longitude: number;
}

export enum AssetStatus {
  REJECTED = "rejected",
  NOT_STARTED = "not_started",
  IN_PROGRESS = "in_progress",
  UNDER_REVIEW = "under_review",
  SUBMITTED = "submitted",
}

export interface Asset extends DraftAsset {
  readonly inspection_id: Id;
  readonly created_at: Date;
  readonly created_by: string;
  readonly uploaded_at?: Date;
  readonly deleted: boolean;

  readonly status?: AssetStatus;
  readonly last_updated?: Date;
  readonly owner?: string;
  readonly received_date?: Date;
  readonly completion_date?: Date;
  readonly person_in_charge?: string;
  readonly confirmation_date?: Date;
  readonly primary_approver?: string;
  readonly secondary_approver?: string;
  readonly house_inspection?: string;
  readonly reception_id?: string;
  readonly diagnostic_id?: string;

  // bridge-specific fields
  readonly name?: string;
  readonly route_name?: string;
  readonly administrator_name?: string;
  readonly ground_conditions?: string;
  readonly alternative_route_availability?: number; // 1 = Easy - 10 = Hard
  readonly is_road_private?: boolean; // false = public, true = private
  readonly route_strategic_importance?: string;
  readonly construction_year?: number;
  readonly span_meters?: number;
  readonly width_meters?: number;
  readonly traffic_volume?: number;
  readonly serves_hospital?: boolean;
  readonly serves_school?: boolean;
  readonly deicing_salts_exposure?: boolean;
}

export interface DraftAssetData {
  readonly asset: Asset | null;
  readonly annotations: ReadonlyArray<Annotation>;
}

export interface AssetData extends DraftAssetData {
  readonly inspection_id: Id;
}

interface AssetTypeMetadata {
  readonly name: string;
  readonly image: string;
}

export const typeMetadata: Record<AssetType, AssetTypeMetadata> = {
  BOX_CULVERT: {
    name: "app:BOX_CULVERT",
    image: PreCachedImages.BOX_CULVERT,
  },
  INTEGRAL_ABUTMENT_BRIDGE: {
    name: "app:INTEGRAL_ABUTMENT_BRIDGE",
    image: PreCachedImages.INTEGRAL_SUPPORT,
  },
  DOUBLE_SPAN_CONTINUOUS_BRIDGE: {
    name: "app:DOUBLE_SPAN_CONTINUOUS_BRIDGE",
    image: PreCachedImages.SPAN_SUPPORT_CONTINUOUS,
  },
  SIMPLY_SUPPORTED_SINGLE_SPAN_BRIDGE: {
    name: "app:SIMPLY_SUPPORTED_SINGLE_SPAN_BRIDGE",
    image: PreCachedImages.SPAN_SUPPORT_SIMPLY_SUPPORTED,
  },
  HOUSE: { name: "app:HOUSE", image: homeOutline },
};
