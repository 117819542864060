import { AuthErrorPage } from "@bridgePages/ErrorPage";
import { IonProgressBar } from "@ionic/react";
import { ContextProvider } from "@mind-foundry/mf-ui-core";
import React from "react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";

import { App } from "./App";
import { isAuthlessUrl } from "./common/auth";
import { ConfigProvider } from "./config";

const container = document.getElementById("root");
const root = createRoot(container!);
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <ContextProvider
      mock={((import.meta.env.VITE_MF_AUTH0_DISABLED) as string) === "1" || isAuthlessUrl()}
      configProvider={ConfigProvider}
      loadingComponent={IonProgressBar}
      authFailureComponent={AuthErrorPage}
      companyNameKey="app:COMPANY_NAME"
      appNameKey="app:PRODUCT_NAME"
    >
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </ContextProvider>
  </React.StrictMode>,
);
